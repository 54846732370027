@import '../../../utils/variables';

.sb-Upsell {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 40px 25px 25px;
	position: fixed;
	z-index: 9999;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	align-items: center;

	&-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		background-color: black;
		z-index: 9999;
		opacity: 0.5;
		width: 100vw;
		height: 100vh;
	}

	&-price {
		position: absolute;
		left: 15px;
	}

	&-popup {
		border-radius: 10px;
	}

	&-fullscreen {
		padding-bottom: 47px;
		width: 110vw;
		height: 120vh;
	}

	&-fullscreen, &__description {
		width: 100% !important;
		justify-content: center !important;
	}

	h2 {
		margin-top: 5px;
		width: 93%;
		font-size: 26px;
		color: $color-purple;
		font-weight: 600;
	}

	> img {
		width: 35vh;
	}

	&__description {
		width: 69%;
		display: flex;
		align-items: center;
		font-size: 16px;
		margin-bottom: 20px;
		text-align: left;

		span {
			margin-left: 15px;
		}

		img {
			width: 45px;
			height: 45px;
		}
	}

	> div > span {
		color: #9a9999;
	}

	//The <button> and <fieldset> elements can't be flex containers in Safari.
	&__button {
		white-space: nowrap;

		&-close {
			position: absolute;
			top: 10px;
			right: 10px;
			background-color: #b3b9be;
			color: #cdcdcd;
			border: none;
			border-radius: 100%;
			width: 20px;
			height: 20px;
			font-size: 12px;
			line-height: 10px;
		}

		&-yes {
			height: 52px;
			display: flex;
			justify-content: center;
			justify-items: center;
			align-items: center;
			align-content: center;
			width: 300px;
			border: none;
			background-image: linear-gradient(96.86deg, #A637FC -1.49%, #F34EF8 169.78%);
			font-weight: 600;
			padding: 15px;
			border-radius: 10px;
			color: #fff;
			font-size: 24px;
		}

		&-no {
			color: #9a9999;
			text-decoration: underline;
			font-size: 14px;
			height: 35px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 300px;
			border: none;
			border-radius: 10px;
			margin-top: 10px;
			padding: 10px;
			background-color: #fff;
		}
	}

	&-transition {
		&-enter {
			opacity: 0;
		}

		&-enter-active {
			opacity: 1;
			transition: opacity 700ms;
		}

		&-exit {
			opacity: 1;
		}

		&-exit-active {
			opacity: 0;
			transition: opacity 700ms;
		}
	}
}

