@import '../../../utils/variables';

.sb-Boards {
  width: $desktop-width;
  // height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
  flex: 1;
  .crop-area {
    border: none;
  }

  &-orderCompleted {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-orderStatus {
    position: fixed;
    top: 150px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    text-align: center;
    color: $color-cool;
    background: #fff;
    line-height: 2.5;
    font-size: 15pt;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;

    @include mq(phone-only) {
      margin-top: 70px;
    }

    @media only screen and (max-height: 700px) {
      font-size: 12pt;
    }
  }

  &-reload {
    color: orange;
    cursor: pointer;
  }

  &-backdrop {
    position: absolute;
    width: 100%;
    background-color: grey;
    opacity: 0.4;
    z-index: -1;
    height: 100vh;
  }

  @media only screen and (max-width: 989px) {
    width: $mobile-width;
    overflow: hidden;
  }

  @include mq(phone-only) {
    height: 100%;
    flex: 1;
    overflow-y: hidden;
    background-color: $color-background;
  }

  // // iphone xr
  // @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  // 	height: calc(100vh - 650px);
  // }

  // //iphone x, xs
  // @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  // 	height: calc(100vh - 265px);
  // }

  // //iphone xs max
  // @media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  // 	height: calc(100vh - 265px);
  // }
}

.controls-text {
  @media only screen and (max-width: 376px) {
    display: none;
  }
}

.controls-gap {
  @media only screen and (max-width: 376px) {
    gap: 0px !important;
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.shadow {
  transform: translateZ(0);
  filter: drop-shadow(0px 27px 8px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 17px 7px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.08))
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.13))
    drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.15));
}
