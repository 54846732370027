@import '../../../utils/variables';

.customshipping {
	margin-top: -25px;
	color: $color-font;
	display: flex;
	flex-direction: column;
	white-space: nowrap;
	gap: 8px;
	h4 {
		margin-bottom: 6px;
	}

	label {
	    font-size: 14px;
		font-weight: 400;
		line-height: 28px;
	}

	.checkbox {
		margin-right: 8px;
	}

	&__option {
		width: 100%;
		font-weight: 500;
		display: flex;
		cursor: pointer;
		&__price {
			font-weight: 600;
			opacity: 0.5;
		}
		&-selected {
			&__price {
				-webkit-font-smoothing: antialiased !important;
				font-weight: 500 !important;
				opacity: 1;
			}
		}
	}
}
