@import "../../../utils/variables";

.sb-EmailPopup {
	background: white;
	background-image: linear-gradient(96.86deg, #A637FC -1.49%, #F34EF8 169.78%);
	width: 90vw;
	max-width: 480px; 
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 50;
	padding: 60px 30px 40px 30px;
	border-radius: 19px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	align-items: center;
	font-size: 20px;
	line-height: 30px;
	color: white;
	border: 5px solid #FFFFFF;

	@media only screen and (min-width: 700px) { 
		padding: 60px 0;
	}
 
	&__backdrop {
		background-color: black;
		height: 100vh;
		width: 100vw;
		position: fixed;
		opacity: 0.5;
		top: 0;
		z-index: 50;
	}

	&__close { 
		font-weight: 500;
		background: none;
		border: none;
		position: absolute;
		padding: 0;
		right: 20px; 
		top: 20px;
		cursor: pointer;

		img {
			width: 16px; 
		}
	}

	&__save {
		border: none;
		border-radius: 15px; 
		background-color: white;
		color: white;
		font-size: 15px;
		cursor: pointer;
		align-self: center;
		letter-spacing: 0.5px;
		text-transform: capitalize;
		width: inherit;
		font-weight: 700;
		height: 40px;
		color: #BF3EFB;
		
		&:disabled {
			cursor: default;
			background: #BEBEBE;
			color: white;
		}

		@include mq(phone-only) {  
			width: 100%;
		}
	}

	&__notification{
		display:flex;
		flex-direction: column;
		text-align: center;
		align-items: center;
		font-weight: 600;

		img {  
			width: 40px;
			margin-bottom: 40px; 
		}

		span { 
			font-size: 27px;
			line-height: 37px; 
			font-weight: 400;
			letter-spacing: 0.5px; 
			margin-bottom: 0 !important;
		}
		margin-bottom: 0 !important;
	};

	&__error {
		font-size: 16px;
		font-weight: 600;
		text-align: center;
		line-height: 20px;
		margin-bottom: 40px;
		margin-top: -13px;
		width: 70%;

		@include mq(phone-only) {  
			width: unset;
		}
	}
	
	&__subtitle {
		text-align: center;
		text-transform: capitalize;
		font-weight: 600;
		font-size: 25px;
		letter-spacing: 0.7px;
		white-space: nowrap;
		margin-bottom: 35px;

		@include mq(phone-only) {  
			font-size: 20px;
		}
	}  

	&__title { 
		font-size: 40px;
		margin-bottom: 47px;
		font-weight: 700;
		letter-spacing: 0.55px;
		opacity: 1;

		@include mq(phone-only) {  
			font-size: 30px;
		}
	} 

	&__bookmark {    
		width: 160px;
		position: absolute;
		top: -68px; 
	}

	
	&__logo {     
		width: 160px; 
	}

	&__email{
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 80%;

		@include mq(phone-only) {   
			width: 100%; 
		}

		p {
			margin: 12px;
			width: 60%;
			font-size: 22px;
			width: inherit;   
			font-weight: 400;
			text-align: center;  

			@include mq(phone-only) {   
				font-size: 20px; 
			}
		}

		input {
			text-transform: lowercase;
			font-weight: 500;
			color: white;
			height: 40px;
			width: inherit;  
			padding: 15px;
			font-size: 16px;
			margin: 15px 0 20px 0;
			background: rgba(40, 78, 123, 0.05);
			border-radius: 12px;
			border: 1px solid rgba(255, 255, 255, 0.8);
			box-sizing: border-box;
	
			&:focus{
				outline: none;
	
			}
	
			&::placeholder { 
				text-transform: initial;
				font-weight: 400;
				letter-spacing: 0.5px; 
				color: white;
				opacity: 0.8;
			}
	
			@include mq(phone-only) {  
				width: 100%;
			}
	
		}  
	}
}
