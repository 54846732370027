.sb-BoardLoader {
	display: inline-block;
	position: absolute;
	width: 64px;
	height: 64px;
	left: 50%;
	transform: translate(-50%, -50%) !important;
	margin-top: 50%;

	> div {
		position: absolute;
		width: 5px !important;
		height: 5px !important;
		background: rgb(150, 150, 150);
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;

		&:nth-child(1) {
			animation-delay: 0s;
			top: 29px !important;
			left: 53px !important;
		}

		&:nth-child(2) {
			animation-delay: -0.1s;
			top: 18px !important;
			left: 50px !important;
		}

		&:nth-child(3) {
			animation-delay: -0.2s;
			top: 9px !important;
			left: 41px !important;
		}

		&:nth-child(4) {
			animation-delay: -0.3s;
			top: 6px !important;
			left: 29px !important;
		}

		&:nth-child(5) {
			animation-delay: -0.4s;
			top: 9px !important;
			left: 18px !important;
		}

		&:nth-child(6) {
			animation-delay: -0.5s;
			top: 18px !important;
			left: 9px !important;
		}

		&:nth-child(7) {
			animation-delay: -0.6s;
			top: 29px !important;
			left: 6px !important;
		}

		&:nth-child(8) {
			animation-delay: -0.7s;
			top: 41px !important;
			left: 9px !important;
		}

		&:nth-child(9) {
			animation-delay: -0.8s;
			top: 50px !important;
			left: 18px !important;
		}

		&:nth-child(10) {
			animation-delay: -0.9s;
			top: 53px !important;
			left: 29px !important;
		}

		&:nth-child(11) {
			animation-delay: -1s;
			top: 50px !important;
			left: 41px !important;
		}

		&:nth-child(12) {
			animation-delay: -1.1s;
			top: 41px !important;
			left: 50px !important;
		}
	}
}

@keyframes lds-default {
	0%,
	20%,
	80%,
	100% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.5);
	}
}
