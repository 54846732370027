.sb-Footer-button > div {
	top: 0 !important;
	width: 100%;
	height: 100% !important;
	// width: 340px !important;
}

.sb-ApplePayButton {
	padding: 0 !important;
	border: none !important;
	background-color: unset !important;
}
