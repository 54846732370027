@import '../../../utils/variables';



.sb-ProcessingPayment {
	width: 100%;
	height: 100%;
	background-color: $color-background;

	&-orderStatus {
		margin-top: 120px;

		text-align: center;
		color: $color-pink-dark;
		font-weight: bold;
		background: transparent;
		line-height: 2.5;
		font-size: 25pt; 

		@media only screen and (max-height: 700px),
		screen and (min-width: 455px) {
			margin-top: 160px;
		}

		@include mq(phone-only) {
			margin-top: 70px;
			font-size: 23pt;

		}
	}

	&-successWrapper {
		width: 400px;
		height: fit-content;
		margin: 0 auto;
		margin-top: calc(50vh - 350px);
		border-radius: 4px;

		// >h3 {
		// 	padding-top: 20px;
		// 	color: #fff;
		// 	text-align: center;
		// 	font-size: 16pt;
		// }

		// >p {
		// 	color: #fff;
		// 	text-align: center;
		// 	font-size: 13pt;
		// }
		&-text {
			line-height: 1.48;
			color: #6f6f6e;
			font-size: 14pt;
			font-weight: 500;
			display: inline-block;
			text-align: center;
			width: 100%;

		}

		@media only screen and (max-width: 989px) {
			width: 70%;
			margin: 0 auto;
		}
	}

	@media only screen and (max-width: 470px) {
		position: fixed;
		height: calc(100vh - 30px);
		//margin-top: 30px;
	}
}

footer {
	background: #fff;
	position: fixed;
	bottom: 0;
	width: 100%;

	@media only screen and (max-width: 989px) {
		width: 100%;
	}

	@include mq(phone-only) {
		padding: 0 10px;
		width: calc(100% - 20px);
	}

	>div:first-child {
		width: 100%;
		height: fit-content;

		>p {
			color: #acacac;
			text-align: left;

			@include mq(phone-only) {
				text-align: center;
			}

			>img {
				width: 30px;
			}

			>span {
				padding-left: 10px;
				font-weight: bold;
				font-size: 13pt;
				position: relative;
				top: -7px;
			}
		}
	}

	>div:nth-child(2) {
		text-align: center;
		padding-top: 10px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

		@include mq(phone-only) {
			margin-left: -10px;
			width: 100%;
			padding: 10px 10px 0;
		}

		>button {
			margin-bottom: 20px;
			padding: 10px 15px;
			background-color: $color-action;
			border-radius: 0.3em;
			text-decoration: none;
			border: none;
			outline: none;

			&:disabled {
				background: rgb(172, 172, 172) !important;
				cursor: not-allowed;
			}

			@include mq(phone-only) {
				width: 100%;
				height: 100%;
			}

			>span {
				font-size: 30pt;
				font-weight: 700;
				margin-bottom: 5px;
				color: #fff;

				@media only screen and (max-width: 1630px) {
					font-size: 30pt;
				}

				@media only screen and (max-width: 1440px) {
					font-size: 24pt;
				}

				@media only screen and (max-width: 1330px) {
					font-size: 20pt;
				}

				@media only screen and (max-width: 1060px) {
					font-size: 16pt;
				}

				@media only screen and (max-width: 989px) {
					font-size: 18pt;
				}

				@include mq(phone-only) {
					font-size: 22pt;
				}
			}
		}
	}
}

.progress {
	height: 10px;
	width: 300px;
	margin: 0 auto;
	border-radius: 4px;
	margin-bottom: 20px;
	overflow: hidden;
	background-color: #afafaf !important;

	>div {
		height: 100%;
		background-color: $color-pink-dark;
	}

	@media only screen and (max-height: 700px),
	screen and (min-width: 455px) {
		margin-top: 50px;
	}

	@media only screen and (max-width: 989px) {
		width: 95%;
	}
}
