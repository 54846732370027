@import '../../../utils/variables';

.Gift {
  width: $desktop-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  // overflow-y: hidden;
  position: relative;

  .gift-up-target {
    margin-top: 24px;

    @include mq(phone-only) {
      margin: 24px 10px;
    }
  }

  @include mq(phone-only) {
    margin: 0;
    width: 100%;
  }
}

.btn,
.btn-lg,
.font-size-base,
.btn-primary,
.btn-block {
  color: white !important;
}

#items-page .btn-primary,
#items-page span {
  color: white !important;
}
