@import '../../../utils/variables';

.sb-Header {
  top: 0px;
  // height: 48px;
  display: flex;
  padding: 12px;
  position: relative;
  align-items: center;
  width: $desktop-width;

  a,
  a:visited,
  a:hover,
  a:focus {
    color: #c03cfc;
  }

  &__left {
    display: flex;
    align-items: center;
    height: 50px;
  }

  &-back-arrow {
    width: 24px;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 3px 0 13px;
    cursor: pointer;
  }

  &-header-message {
    margin-left: 20px;
    color: $color-font;
    letter-spacing: 0.4px;
    font-size: 17px;

    span {
      color: $color-pink;
      font-weight: 700;
    }

    @include mq(phone-only) {
      display: none;
    }
  }

  &-chat-logo {
    display: none;

    @include mq(phone-only) {
      width: 23px;
      height: 35px;
      margin: 0 19px;
      display: inline-block;
    }
  }

  &-logo {
    color: white;
    font-size: 18pt;
    align-self: center;

    @media only screen and (min-width: 700px) {
      // position: relative;
      // left: -110px;
    }

    @media only screen and (max-width: 1180px) {
      // font-size: 17pt;
    }

    @media only screen and (max-width: 442px) {
      // font-size: 13.25pt;
    }

    @include mq(phone-only) {
      // font-size: 12pt;
    }

    @media only screen and (max-width: 375px) {
      // font-size: 10.8pt;
    }

    @media only screen and (max-width: 360px) {
      // font-size: 8pt;
    }

    h1 {
      position: relative;
      font-weight: 900;
      margin: 0;
      font-size: 26px;
      height: 100%;
      align-self: center;
      font-family: 'Avenir Next', sans-serif !important;

      @include mq(phone-only) {
        // font-size: 16pt;
      }

      @media only screen and (max-width: 375px) {
        // font-size: 15pt;
      }

      @media only screen and (max-width: 360px) {
        // font-size: 15pt;
      }

      @media only screen and (max-width: 320px) {
        // font-size: 17pt;
        padding: 0;
        padding-right: 10px;
      }

      // span {
      //   font-size: 26px;
      //   font-weight: 300;
      //   font-family: 'Avenir Next', sans-serif !important;
      // }
    }

    > span {
      font-weight: 400;

      @media only screen and (max-width: 715px) {
        display: none;
      }
    }

    @media only screen and (max-width: 767px) {
      flex-grow: 0;
      margin-right: 20px;
    }
  }

  &-menu-button {
    width: 28px;
    height: 20px;
    // margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    span {
      height: 4px;
      border-radius: 4px;
      background-color: $color-font;
      transition: margin-left 0.2s ease;
    }

    span:nth-child(1) {
      width: 85%;
      margin-left: 15%;
    }

    span:nth-child(2) {
      width: 70%;
      margin-left: 30%;
    }

    span:nth-child(3) {
      width: 100%;
    }

    &--active > span:nth-child(1),
    &--active > span:nth-child(2),
    &:hover > span:nth-child(1),
    &:hover > span:nth-child(2) {
      margin-left: 0;
    }

    @media only screen and (max-width: 480px) {
      margin-top: 0px;
    }
  }

  &-menu-dropdown {
    position: fixed;
    top: 16px;
    left: 16px;
    z-index: 9;
    margin-top: 50px;
    font-weight: 500;

    @include mq(phone-only) {
      top: 54px;
      margin-top: 4px;
      margin-right: 4px;
    }

    // @media only screen and (max-width: 375px) {
    //   width: 280px;
    //   margin-right: -13px;
    // }

    // @media only screen and (max-width: 360px) {
    //   width: 250px;
    //   margin-right: 17px;
    // }

    // @media only screen and (max-width: 320px) {
    //   width: 250px;
    // }

    svg {
      width: 24px;
      height: 24px;
      color: #c03cfc;
      margin-right: 8px;
    }

    a {
      display: block;
      text-decoration: none;
    }

    a > div,
    div {
      padding: 8px;
    }

    a,
    div:not(:last-child) {
      width: 100%;
      content: '';
      border-bottom: 1px solid rgba(40, 78, 123, 0.1);
    }

    div {
      display: flex;
      cursor: pointer;
      align-items: center;
      // background-color: #fff;
      color: rgb(40, 78, 123);

      // @media only screen and (max-width: 375px) {
      //   margin-bottom: 7px;
      //   padding: 2px;
      //   padding-left: 10px;
      //   width: 240px;
      // }

      // span {
      //   margin-left: 10px;
      //   font-size: 14pt;

      //   @media only screen and (max-width: 375px) {
      //     margin-right: 10px;
      //     font-size: 12pt;
      //   }

      //   @media only screen and (max-width: 360px) {
      //     margin-right: 10px;
      //     top: 36px;
      //     font-size: 12pt;
      //   }

      //   @media only screen and (max-width: 320px) {
      //     margin-right: 10px;
      //     font-size: 13pt;
      //     font-size: 12pt;
      //   }
      // }

      img {
        width: 23px;
        margin-left: 20px;
        margin-right: 5px;
      }
    }
  }

  @media only screen and (min-width: 599px) {
    // height: $desktop-header-height;
    // background-color: $color-background;

    &-menu-button {
      width: 45px;
      height: 35px;

      span {
        height: 7px;
      }
    }

    // &-menu-dropdown {
    //   right: 20px;
    // }
  }

  @media only screen and (min-width: 990px) {
    &-logo {
      h1 {
        // left: 11%;
        font-family: 'Avenir Next', sans-serif !important;
      }

      &--arrow {
        margin-left: auto;
        h1 {
          left: 5%;
          font-family: 'Avenir Next', sans-serif !important;
        }
      }
    }
  }

  @include mq(phone-only) {
    gap: 8px;
    display: flex;
    align-items: center;

    &--arrow {
      grid-template-columns: 100px 1fr 40px;
    }

    &--gift {
      display: none;
    }

    &-logo {
      &--arrow {
        margin-left: auto;
      }
    }
  }

  @include mq(phone-only) {
  }

  > div:last-child {
    position: relative;
  }

  @include mq(small-desktop-up) {
    &-menu-button {
      width: 28px;
      height: 20px;

      span {
        height: 4px;
      }
    }
  }
}
