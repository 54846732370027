.login > form > p,
.login > form > label > p,
.login > p,
.portal > p,
.portal > div > p,
.portal > div > div,
.portal > div > div > p,
.portal > div > div > div > p {
  margin: 0;
  font-family: 'Plus Jakarta Sans', sans-serif;
}

button:disabled {
  background-image: unset;
  background-color: #b7bec3;
}

.list > li:not(:last-child) {
  margin-bottom: 5px;
}

.card {
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background: linear-gradient(
    88.77deg,
    #ff8f3f -4.82%,
    #dd07c4 37.41%,
    #ad37fd 102.1%
  );
  box-shadow:
    inset 0px -1px 1px 1px rgba(0, 0, 0, 0.1),
    inset 0px 1px 1px 1px rgba(255, 255, 255, 0.35);
  border-radius: 30px;
}

@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Plus+Jakarta+Sans:wght@200..800&display=swap');

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}
