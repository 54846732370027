.paypalbuttons{

	&-hidden{
		display: none !important;
	}
	&-fake{
		div:nth-of-type(2){
			display: none !important;
		}
	}
	&-real{
		> div:nth-of-type(1){
			display: none !important;
		}
		> div:nth-of-type(2){
			width: 100%;
			position: relative;
		}
	}
}

div[data-pp-message]{
	opacity: 1 !important;
}

.klarnabutton {
	&-hidden {
		display: none !important;
	}
	&-fake, &-real{
		> button{
			background-color: #ffb3c7;
			border: none;   
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			cursor: pointer;

			span{
				display: inline-block;
				color: #171717;
				font-size: 18px; 
				font-weight: bold;
				margin-right: 5px;
			}
			img{
				width: 90px;
				// margin-top: -2px;
			}
		}
	}
	&-fake{ 
		button:nth-of-type(2){
			display: none !important;
		}
	}
	&-real{
		> button:nth-of-type(1){
			display: none !important;
		}
		> button:nth-of-type(2){
			width: 100%;
			position: relative;
		}
	}
}  
