@import '../../../utils/variables';

.sb-LowQuality {
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 50;
	background-color: rgba(0, 0, 0, 0.5);

	&-content {
		bottom: 50%;
		transform: translateY(50%);
		max-width: 600px;
		width: 100%;
		height: 500px;
		margin: 5px auto;
		padding: 0 10px;
		display: flex;
		flex-direction: column;
		position: fixed;
		top: auto;
		left: 0;
		right: 0;
		z-index: 13;
		box-sizing: border-box;

		&_slideUp {
			animation: slideUp ease-in 0.2s;
		}

		&_slideDown {
			animation: slideDown ease-in 0.2s;
		}

		> div {
			margin: 5px 0;
			display: flex;
			flex-direction: column;
			background-color: #fff;
			@extend %drop-shadow-5;
		}
	}

	&-image {
		padding-top: 24px;
		flex: 3;
		justify-content: space-between;
		align-items: center;

		> img {
			max-height: 250px;
			max-width: 90%;
		}
	}

	&-menu {
		flex: 1;

		> span {
			display: flex;
			flex: 1;
			justify-content: center;
			align-items: center;
			font-size: 18px;
			font-weight: 700;
			cursor: pointer;

			&:first-child {
				color: $color-purple;
				border-bottom: 2px solid #dedede;
			}
		}
	}

	&-title {
		padding: 0 24px 10px;
		text-align: center;
		font-size: 20px;
		font-weight: 700;
	}

	&-text {
		padding: 0 24px 20px;
		text-align: center;
		font-weight: 700;
		color: #8c8c8c;
		white-space: pre-line;
	}
}

@keyframes slideUp {
	from {
		bottom: -100%;
	}

	to {
		bottom: 0;
	}
}

@keyframes slideDown {
	from {
		bottom: 0;
	}

	to {
		bottom: -100%;
	}
}
