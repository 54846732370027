@import '../../../utils/variables';

.sb-Spinner {
	width: 80px;
	height: 80px;
	z-index: 3;
	margin: 0 auto 70px;

	&-initial{ 
		position: fixed;
		top: 50%;
		left: 50%; 
		transform: translate(-50%, -50%); 
		height: 80px;   
		width: 80px;
	}

	&-cube {
		width: 33%;
		height: 33%;
		background-color: $color-pink-dark;
		float: left;
		animation: sb-SpinnerScaleDelay 1.3s infinite ease-in-out;

		&1 {
			animation-delay: 0.2s;
		}

		&2 {
			animation-delay: 0.3s;
		}

		&3 {
			animation-delay: 0.4s;
		}

		&4 {
			animation-delay: 0.1s;
		}

		&5 {
			animation-delay: 0.2s;
		}

		&6 {
			animation-delay: 0.3s;
		}

		&7 {
			animation-delay: 0s;
		}

		&8 {
			animation-delay: 0.1s;
		}

		&9 {
			animation-delay: 0.2s;
		}
	}
}

@keyframes sb-SpinnerScaleDelay {
	0%,
	70%,
	100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1);
	}

	35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1);
	}
}
