@import "../../../utils/variables";

.upload-right {
	frame {
		> div {
			background: none;
		}
	}
}

.sb-UploadImage {
	&-right {
		background: black;
	}

	// transform: scale(1.09);

	&-edge,
	&-clean,
	&-ever,
	&-bold {
		transform: scale(1.01);
		box-shadow: rgba(10, 37, 64, 0.35) 1px -1px 5px 0px inset;
	}

	&-clean,
	&-ever,
	&-edge,
	&-bold {
		transform: scale(0.93);
	}

	width: 100%;
	height: 100%;
	background: #fff;
	cursor: pointer;
	z-index: 0;

	&-drag-drop {
		display: none;
		@media screen and (min-width: 990px) {
			display: block;
		}
	}

	&-frame {
		height: 100%;
		padding: 6px;
		box-sizing: border-box;
		position: relative;

		> div {
			@extend %borderDance;
			@extend %uploadFrameAnimation;
		}

		img {
			height: 100px;

			@media screen and (min-width: 599px) {
				height: 60px;
			}
		}
	}

	&-corner {
		width: 10px;
		height: 10px;
		background-color: #1d3e64;
		position: absolute;

		&:nth-child(2) {
			top: 5px;
			left: 5px;
		}

		&:nth-child(3) {
			top: 5px;
			right: 5px;
		}

		&:nth-child(4) {
			bottom: 5px;
			left: 5px;
		}

		&:nth-child(5) {
			bottom: 5px;
			right: 5px;
		}
	}

	&-coupon {
		img {
			position: absolute;
			height: 210px;
		}
		p {
			font-size: 26px;
		}

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		width: 170px;
		height: 170px;
		position: relative;
		> div {
			flex-direction: column;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
		}
	}

	&-discount {
		&-coupon {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			color: #fff;
			top: 140px;
			font-size: 12px;

			@media screen and (min-width: 990px) {
				font-size: 14px;
			}

			&.coupon-bund {
				top: 108px;
				font-weight: bold;
				font-size: 22px;
				@media screen and (min-width: 990px) {
					top: 112px;
				}
			}

			&.coupon-bund:nth-of-type(2) {
				top: 132px;
				font-size: 14px;
				@media screen and (min-width: 990px) {
					top: 138px;
				}
			}
		}
		position: absolute;
		top: 107px;
		left: 50%;
		font-size: 28px;
		font-weight: 700;
		color: #ffffff;
		transform: translateX(-50%);
		z-index: 99;
	}

	&-upload-options {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		position: relative;
		align-items: center;
		font-size: 13px;

		> div {
			img {
				width: 90%;
				position: unset !important;
			}
		}
	}

	&-upload-options {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		position: relative;
		align-items: center;
		font-size: 13px;

		> div {
			img {
				width: 90%;
				position: unset !important;
			}
		}
	}

	&-hr {
		width: 100%;
		border-bottom: 1px solid;
		position: absolute;
		top: 50%;
	}

	&-upload {
		&-storage {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&-icon {
			animation: pulse 2s infinite;
			animation-timing-function: ease-in-out;
			position: relative !important;
			width: 100px !important;
			height: 100px !important;

			@media screen and (min-width: 599px) {
				margin-bottom: 20px;
			}

			@media screen and (min-width: 599px) {
				height: 80px;
				margin-bottom: 20px;
			}
		}
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}
