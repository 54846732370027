@import '../../../utils/variables';

.MuiPaper-root {
  height: calc(100vh - calc(100vh - 100%)) !important;
  background-color: #e5e5e5 !important;
}

.sb-ShippingInfo {
  width: 100%;
  background-color: #f5f6f7;
  box-shadow: 0 1px 59px rgba(35, 33, 66, 0.1);
  z-index: 12;
  padding-left: 15px;
  padding-right: 15px;
  &-header {
    height: 50px;
    width: calc(100% + 30px);
    text-align: center;
    background: #fff;
    margin-left: -15px;
    padding: 0 15px;
    z-index: 13;

    &-btnDone {
      display: flex;
      align-items: center;
      @include btn-done;
      user-select: none;

      @include mq(phone-only) {
        font-size: 15pt;
      }

      @media only screen and (max-width: 367px) {
        font-size: 15pt;
      }
    }

    &-btnCancel {
      width: 25px;
      height: 50px;
      float: left;
      cursor: pointer;

      @include mq(phone-only) {
        width: 14px;
      }

      @media only screen and (max-width: 367px) {
        width: 18px;
      }
    }

    &-title {
      color: rgb(206, 206, 206);
      color: #e5e5e5;
      line-height: 2;
      font-size: 20pt;
      font-weight: 700;

      @include mq(phone-only) {
        font-size: 13pt;
        line-height: 3;
        margin-left: 7%;
      }

      @media only screen and (max-width: 367px) {
        font-size: 13pt;
        line-height: 3;
      }
    }
  }

  &-title {
    padding: 20px 0;
    display: flex;

    > img {
      margin-right: 3%;
    }

    > span {
      color: $color-action;
      color: #a637fc;
      font-size: 6vw;
      margin-left: 10px;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  &-errorMsg {
    text-align: center;
    color: $color-error;
  }

  &-form {
    width: 100%;
    overflow: auto;

    &-select,
    &-input {
      width: calc(100% - 30px);
      border-radius: 4px;
      border: none;
      font-size: 18px;
      padding: 10px 15px;
      outline: none;
      box-shadow:
        rgba(50, 50, 93, 0.15) 0 1px 3px,
        rgba(0, 0, 0, 0.02) 0 1px 0;
      font-weight: 500;
      margin: 5px 0;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &::placeholder {
        color: #aab7c4;
        font-weight: 500;
      }
    }

    &-select {
      width: 100%;
      padding: 10px;
      appearance: none;
      background: url(../../../assets/images/elements/arrow-right.svg) no-repeat
        calc(100% - 10px) #fff;
      background-size: 20px;
    }
  }

  &-errorMessage {
    color: $color-error;
    margin: 5px 0;
    font-size: 10pt;
    @extend %fadeAndGrowAnimation;
  }

  &-fadeIn {
    opacity: 1;
    visibility: visible;
  }

  &-fadeOut {
    opacity: 0;
    visibility: hidden;
  }

  @media only screen and (min-width: 990px) {
    // right: $modal-right-medium;
    left: 50%;
    transform: translate(-50%);
    bottom: 0;
    width: $footer-button-width-medium;
    height: 672px;
    overflow: visible;
    border-radius: 4px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.17, 0.84, 0.44, 1);
    padding-left: 20px;
    padding-right: 20px;
    cursor: auto;
    padding-bottom: 15px;
    // margin-bottom: 10px;
    transition: all 0.2s ease-in-out;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      width: $footer-button-width-medium;
      margin-left: -20px;
      text-align: center;
      background: #fff;
      padding: 0 15px;
      border-radius: 16px 16px 0 0;

      &-btnDone {
        color: $color-action;
        color: $color-pink-dark;
        border: none;
        outline: none;
        font-size: 14pt;
        font-weight: bold;
        padding: 0;
        background: #fff;
        cursor: pointer;
      }

      &-btnCancel {
        width: 16px;
        cursor: pointer;
      }

      &-title {
        line-height: 2;
        font-size: 15pt;
        font-weight: 700;
        letter-spacing: 0.7px;
        margin-left: 20px;
      }
    }

    &-title {
      padding-top: 20px;
      margin-top: 0px;
      display: flex;

      > img {
        width: 27px;
      }

      > span {
        color: $color-action;
        color: #a637fc;
        font-weight: bold;
        font-size: 27px;
        margin-left: 7px;
      }
    }

    &-errorMsg {
      text-align: center;
      color: $color-error;
    }

    &-form {
      width: 100%;
      overflow: auto;
      letter-spacing: 0.5px;

      &-select,
      &-input {
        width: calc(100% - 30px);
        border-radius: 4px;
        border: none;
        font-size: 18px;
        padding: 10px 15px;
        outline: none;
        box-shadow:
          rgba(50, 50, 93, 0.15) 0 1px 3px,
          rgba(0, 0, 0, 0.02) 0 1px 0;
        font-weight: 500;
        margin: 5px 0;

        &:focus {
          border: 1px solid $color-action;
        }

        &::placeholder {
          color: #aab7c4;
          font-weight: 400;
        }
      }

      &-select {
        width: 100%;
        padding: 10px;
        appearance: none;
        background: url(../../../assets/images/elements/arrow-right.svg)
          no-repeat calc(100% - 10px) #fff;
        background-size: 20px;
      }
    }

    &-errorMessage {
      color: $color-error;
      margin: 5px 0;
      font-size: 10pt;
      animation: fade-and-grow 0.2s;
    }
  }

  @include mq(medium-desktop-up) {
    &-header {
      width: $footer-button-width;
    }
  }

  @media only screen and (min-width: 990px) and (min-height: 600px) {
    bottom: 0;
    z-index: 42;
  }

  @media only screen and (min-width: 990px) and (min-height: 701px) {
    bottom: 0;
  }

  @include mq(small-desktop-up) {
    // width: $footer-button-width;
  }

  @media only screen and (min-width: 1440px) and (min-height: 701px) {
    height: 672px;
    right: $modal-right;
  }

  @include mq(medium-desktop-up) {
    width: $footer-button-width;
  }
}

.country-select {
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}

.autocomplete-root {
  display: inline-grid;
  grid-template-columns: 5fr 2fr;
  align-items: start;
  > div {
    margin-right: 10px;
    flex-basis: 60%;
  }
}

.suggestion-item {
  background-color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.suggestion-item--active {
  background-color: $color-action;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.autocomplete-dropdown-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 100;
  top: 230px;
  left: 0;
  right: 0;
  border-radius: 0 0 5px 5px;
  overflow: hidden;

  @media only screen and (min-width: 700px) {
    top: 250px;
  }

  @media only screen and (min-width: 990px) {
    top: 220px;
  }
}

input[aria-expanded='true'] + .autocomplete-dropdown-container {
  border: 1px solid #00b0ff;
}

input[aria-expanded='true'] ~ .autocomplete-dropdown-container {
  border: 1px solid #00b0ff;
}

.address_error {
  color: #e51b72;
  margin: 5px 0;
  font-size: 10pt;
}
