@import '../../../utils/variables';

// DO NOT CHANGE CSS HERE, CROPPER WON'T BE PRECISE

.sb-ImageCropper-slider {
  display: none;
  @include mq(phone-only) {
    transform: scale(0.6);
    bottom: -50px;
  }
  @media only screen and (min-width: 990px) {
    display: flex;
  }

  &-lefticon {
    width: 30px;
  }

  &-righticon {
    width: 45px;
  }

  input {
    width: 390px;
  }

  z-index: 2;
  width: 550px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  transform: scale(0.68) translateX(-10px);
}

.sb-ImageCropper-slider::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border: none !important;
  height: 106px;
  width: 16px;
  border-radius: 0;
  background: #ffffff;
  cursor: pointer;
}

.sb-ImageCropper {
  @include mq(phone-only) {
    position: relative;
    top: 20px;
    transform: scale(1.1);
  }

  transform: scale(1);
  width: 320px;
  height: 320px;
  display: inline-flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  position: relative;

  &-frame {
    img {
      width: 133%;
      height: 133%;
      position: absolute;
      top: 57%;
      left: 47.4%;
      z-index: 0;
      transform: translate(-50%, -50%);
      display: none;

      &.show {
        display: block;
      }
    }
    &.isClassic {
      img {
        width: 124%;
        height: 123%;
        top: 56.5%;
        left: 47.4%;
      }
    }
  }

  &-container {
    width: 255px;
    height: 255px;
    position: relative;

    &-bold,
    &-ever {
      width: 255px;
      height: 255px;
    }

    > div:nth-child(2) {
      transform: translateZ(0);
      transform: scale(1.005);
      @include mq(phone-only) {
        transform: scale(1.01);
      }
    }

    &-clean,
    &-edge {
      > div:nth-child(2) {
        transform: translateZ(0);
        transform: scale(0.85);
        @include mq(phone-only) {
          transform: scale(0.85);
        }
      }
    }
  }
  @media only screen and (max-height: 768px) {
    // transform: scale(1.3);
  }

  @media only screen and (min-width: 700px) and (min-height: 700px) {
    top: -10px;
    // transform: scale(1.252);
  }
  @media only screen and (min-width: 700px) and (min-height: 990px) {
    // transform: scale(1.8);
  }
}

.orientation6-rotate-90 {
  transform: rotate(90deg);
}

.orientation8-rotate90 {
  transform: rotate(90deg);
}

.orientation3-rotate180 {
  transform: rotate(180deg);
}

.crop-img-vertical {
  width: 255px !important;
}

.crop-img-horizontal {
  height: 255px !important;
}

img.image-inside-cropper {
  transform: scale(0.8);
  max-width: unset !important;
  max-height: unset !important;
  margin: auto;
  position: absolute;
  top: 0;
  left: -9994px !important;
  right: -9994px !important;
  z-index: -1;
  opacity: 1;
  will-change: transform;
  transition:
    transform 0.1s ease-out,
    opacity 0.3s 0.3s,
    -webkit-transform 0.1s ease-out;

  &.crop-bold,
  &.crop-ever {
    // top: 8px;
  }
}

.container-cropper {
  left: -1px;
}
