@import '../../../utils/variables';

.spinner_ajPY {
  transform-origin: center;
  animation: spinner_AtaB 0.75s infinite linear;
}
@keyframes spinner_AtaB {
  100% {
    transform: rotate(360deg);
  }
}

.popup {
  @include mq(phone-only) {
    z-index: 22;
    box-shadow: none;
    top: 0;
    right: 0;
    border-radius: 0;
    width: 100vw;
    height: 100vh;
  }

  &-backdrop {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 21;
    &-animation {
      &-enter {
        opacity: 0;
        transition: all 300ms;
        &#{&}-active {
          opacity: 1;
          transition: all 300ms;
        }
      }
      &-exit {
        &#{&}-active {
          opacity: 0;
          transition: all 300ms;
        }
      }
    }
    right: 0;
    overflow: hidden;
  }

  &-final {
    @include mq(phone-only) {
      button {
        font-size: 20px;
      }
    }

    height: 580px;

    .popup-body-message {
      img {
        // width: 67px;
        flex-basis: 67px;
        height: 67px;
        margin-bottom: 10px;
      }

      // height: 300px;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        // width: 100%;
        // height: 200px;
        p {
          font-size: 30px;
        }
        // span {
        //   align-self: center;
        //   width: 280px;
        //   line-height: 34px;
        //   font-weight: 500 !important;
        //   font-size: 24px;
        // }
      }
    }
  }

  font-size: 20px;
  z-index: 41;
  border-radius: 20px;
  width: $footer-button-width-medium;
  height: 400px;
  position: fixed;
  background-color: $color-background;
  box-shadow: 0px 1px 59px rgba(35, 33, 66, 0.1);
  // right: $modal-right-medium;
  // top: calc(#{$desktop-header-height} + 30px);

  @include mq(medium-desktop-up) {
    width: $footer-button-width;
  }

  @include mq(phone-only) {
    width: 100vw;

    height: calc(100vh - #{$mobile-header-height});
  }

  &-steps {
    position: absolute;
    left: 47%;
    transform: translateX(-47%);
    bottom: 0;
    margin: 0 auto;
    color: #b9b6b6;
    font-size: 22px;
    padding: 0 0 20px 20px;
  }

  &-header {
    @include mq(phone-only) {
      border-radius: 0;
      font-size: 20px;
    }
    @media only screen and (max-width: 1440px) {
      font-size: 22px;
    }
    background: #fff;
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 1px 59px rgba(35, 33, 66, 0.1);
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-weight: 600;
    font-size: 26px;

    span {
      color: $color-pink-dark;
      cursor: pointer;
      font-size: 14pt;
    }

    p {
      color: #e5e5e5;
      display: inline-block;
      font-size: 13pt;
      margin-left: 26px;
      font-weight: 700;
      letter-spacing: 0.7px;
    }
  }

  &-body {
    @include mq(phone-only) {
      position: absolute;
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 0 20px;

    &-buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-button {
      @include mq(phone-only) {
        width: 150px;
      }
      cursor: pointer;
      font-size: 26px;
      color: #fff;
      outline: none;
      border: none;
      border-radius: 19px;
      width: 160px;
      height: 60px;
      background-image: linear-gradient(
        96.86deg,
        #a637fc -1.49%,
        #f34ef8 169.78%
      );

      &-back {
        box-shadow: none;
        color: #989c9e;
        font-size: 24px;
        background: none;
        text-align: start;
      }
    }

    button:disabled {
      background-image: unset;
      background-color: #b7bec3;
    }

    &-form {
      svg {
        margin-right: 4px;
        width: 22px;
        path {
          fill: $color-purple;
        }

        @include mq(phone-only) {
          width: 20px;
        }
      }

      display: flex;
      flex-direction: column;
      align-items: center;

      &-error {
        @include mq(phone-only) {
          position: relative;
          top: 10px;
          left: -15px;
          height: 0;
        }
        left: -40px;
        top: 200px;
        position: absolute;
        color: red;
        font-size: 15px;
        margin: 0;
        align-self: flex-start;
        padding-left: 15%;
      }

      input {
        color: #666666;
        margin-top: 20px;
        height: 50px;
        margin-top: 20px;
        padding-left: 30px;
        width: calc(100% - 32px);
        width: 100%;
        padding: 15px 20px;
        outline: none;
        font-size: 18px;
        font-weight: 500;
        background-color: rgba(40, 78, 123, 0.05);
        border: 1px solid rgba(40, 78, 123, 0.1);
        border-radius: 12px;

        &:focus {
          outline: none;
        }
      }
      input::placeholder {
        color: #b6bdc2;
      }

      span {
        @include mq(phone-only) {
          font-size: 22px;
        }
        display: flex;
        color: $color-purple;
        font-weight: 600;
        font-size: 22px;
        text-transform: uppercase;
        align-items: center;
      }

      span,
      input {
        width: 100%;
      }
    }

    &-message {
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;

      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mq(phone-only) {
        width: 100%;
      }

      img {
        flex-basis: 40px;
        height: 40px;
        margin-right: 10px;

        @include mq(phone-only) {
          flex-basis: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }

      div {
        position: relative;
        // border-radius: 10px;
        // padding: 10px;
        font-size: 15px;
        // border: 1px solid #f7f7f7;
        // background-color: #f7f7f7;

        p {
          color: $color-pink-dark;
          text-align: center;
          margin: 0;
        }

        span {
          text-align: center;
          color: grey;
          // display: block;
        }
      }
    }
  }

  @include mq(medium-desktop-up) {
    right: $modal-right;
  }
}
