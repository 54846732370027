@import '../../../utils/variables';

.sb-Input {
  &-field {
    width: 100%;
    margin: 6px 0;
    outline: none;
    font-size: 18px;
    font-weight: 500;
    padding: 12px 20px;
    border-radius: 12px;
    background-color: rgba(40, 78, 123, 0.05);
    border: 1px solid rgba(40, 78, 123, 0.2);

    @include mq(big-desktop-up) {
      padding: 12px 20px;
    }

    &:focus {
      border: 1px solid rgba(40, 78, 123, 0.75);
    }

    &::placeholder {
      color: #aab7c4;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 1px;
      color: $color-font;
      opacity: 0.5;
    }
  }

  &__apt {
    max-height: 42px;
  }

  &-error {
    color: $color-error;
    margin: 5px 0;
    font-size: 10pt;
    @extend %fadeAndGrowAnimation;
  }
}
