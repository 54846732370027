@import '../../../utils/variables';

.sb-StripeMobileForm {
  width: 100%;
  height: fit-content;

  &-input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 500;
    background-color: rgba(40, 78, 123, 0.05);

    @include mq(big-desktop-up) {
      padding: 12px 20px;
    }

    &::placeholder {
      color: #aab7c4;
      font-weight: 300;
      font-size: 14px;
      letter-spacing: 1px;
      color: $color-font;
      opacity: 0.5;
    }
  }

  &-title {
    display: flex;
    margin-top: 30px;
    margin-left: 2px;

    > img {
      width: 30px;

      @media only screen and (max-width: 989px) {
        width: 8%;
      }
    }

    > span {
      color: $color-action;
      color: #a637fc;
      font-weight: bold;
      font-size: 27px;
      margin-left: 17px;

      @media only screen and (max-width: 989px) {
        font-size: 6vw;
      }
    }
  }

  &-errorMessage {
    color: $color-error;
    margin: 5px 0;
    font-size: 10pt;
    animation: fade-and-grow 0.2s;
  }

  &-creditCards {
    padding: 20px 0 20px;

    > img {
      width: calc(16.66% - 5px);
      margin: 0 2px;

      &:last-child {
        margin-top: 10px;
      }

      @media only screen and (min-width: 1440px) and (min-height: 701px) {
        width: calc(12.66% - 5px);
        margin: 0 5px;
      }
    }

    @media only screen and (min-width: 1440px) and (min-height: 701px) {
      padding: 20px 0 20px;
    }
  }
}

@keyframes fade-and-grow {
  from {
    opacity: 0;
    height: 0;
  }

  to {
    opacity: 1;
    height: 20px;
  }
}

.StripeElement {
  outline: none;
  display: block;
  margin: 10px 0;
  padding: 12px 20px;
  background-color: rgba(40, 78, 123, 0.05);

  font-size: 16px;
  border-radius: 4px;
  color: rgb(40, 78, 123);
  border: 1px solid rgba(40, 78, 123, 0.2);

  @include mq(big-desktop-up) {
    padding: 12px 20px;
  }
}

.StripeElement--focus {
  border: 1px solid rgba(40, 78, 123, 0.75);
}
