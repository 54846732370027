@import '../../../utils/variables';

.cropper-text {
  svg {
    display: none;
    path {
      width: 20px;
    }
  }
}

.sb-CropperWrapper {
  position: fixed;
  transition: 0.5s ease-in-out;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 500px;
  height: 480px;
  display: flex;
  flex-direction: column;
  z-index: 100000;
  background-color: $color-background;
  box-shadow: 0px 1px 59px rgba(35, 33, 66, 0.1);
  cursor: auto;

  @media only screen and (min-width: 990px) and (max-height: 990px) {
    height: 95%;
  }
  @media only screen and (min-width: 700px) and (min-height: 700px) {
    height: max-content;
  }
  @media only screen and (min-width: 700px) and (min-height: 990px) {
    height: 800px;
  }

  &-slideDown {
    top: 200%;
  }

  &-background {
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    filter: opacity(0.5);
  }

  &-header {
    border-radius: 10px 10px 0 0;
    height: 50px;
    width: 100%;
    text-align: center;
    background: #fff;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-btnDone {
      @include btn-done;
      @media only screen and (max-width: 367px) {
        font-size: 15pt;
      }
    }

    &-btnCancel {
      width: 25px;
      height: 20px;
      float: left;
      cursor: pointer;

      @media only screen and (max-width: 367px) {
        width: 18px;
      }
    }

    &-title {
      color: #e5e5e5;
      line-height: 2;
      font-size: 17pt;
      padding-left: 40px;
      letter-spacing: 0.7;
      font-weight: 700;

      @media only screen and (max-width: 367px) {
        font-size: 15pt;
        line-height: 2.5;
      }
    }
  }

  &-text {
    &-pinch {
      @media screen and (min-width: 990px) {
        display: none;
      }
    }
    &-slide {
      display: none;
      @media screen and (min-width: 990px) {
        display: block;
      }
    }
    @media screen and (max-height: 850px) {
      // margin-top: 20px;
    }

    svg {
      margin: 30px 0;
    }

    flex-basis: 60px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    color: #afafaf;

    @media only screen and (max-width: 375px) {
      font-size: 24pt;
    }

    @media only screen and (max-width: 320px) {
      font-size: 22pt;
    }

    @media only screen and (min-height: 900px) {
      font-size: 34px;
    }
  }

  @include mq(phone-only) {
    width: 100vw;
    height: 100%;
    margin: 0;
    border-radius: 0;
    // justify-content: space-between;

    &-header {
      border-radius: 0;

      &-btnDone {
        font-size: 15pt;
      }

      &-btnCancel {
        width: 16px;
      }

      &-title {
        font-size: 13pt;
        padding-left: 30px;
        line-height: 2.3;
      }
    }

    &-text {
      color: #c6c1c1;
      font-weight: 400;
      //margin-top: -200px;
      margin-bottom: 15vh;
      font-size: 24px;
    }
  }

  @media only screen and (min-width: 599px) and (max-width: 989px) {
    padding-left: 15px;
    padding-right: 15px;

    &-header {
      width: calc(100% + 30px);
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
