@import '../../../utils/variables';

.sb-PaymentInfo {
  background: #e5e5e5;
  background-color: #f5f6f7;
  box-shadow: 0 1px 59px rgba(35, 33, 66, 0.1);
  cursor: auto;
  // margin-bottom: 4px;

  @include mq(phone-only) {
    width: 100%;
    height: calc(100vh - calc(100vh - 100%));
    padding-left: 20px;
    padding-right: 20px;
  }

  padding-left: 20px;
  padding-right: 20px;

  &-header {
    height: 50px;
    width: calc(100% + 40px);
    text-align: center;
    background: #fff;
    margin-left: -20px;
    padding: 0 15px;

    &-btnDone {
      @include btn-done;
      @include mq(phone-only) {
        font-size: 15pt;
      }

      @media only screen and (max-width: 367px) {
        font-size: 15pt;
      }
    }

    &-btnCancel {
      width: 25px;
      height: 50px;
      float: left;
      cursor: pointer;

      @include mq(phone-only) {
        width: 14px;
      }

      @media only screen and (max-width: 367px) {
        width: 18px;
      }
    }

    &-title {
      color: rgb(206, 206, 206);
      color: #e5e5e5;
      line-height: 2;
      font-size: 20pt;

      @include mq(phone-only) {
        font-weight: bold;
        font-size: 13pt;
        line-height: 3;
        margin-left: 7%;
      }

      @media only screen and (max-width: 375px) {
        font-size: 13pt;
        line-height: 3;
      }
    }
  }

  &-creditCards {
    width: 100%;
    height: fit-content;
    text-align: center;
    padding-top: 20px;

    > img {
      width: calc(16.75% - 5px);
      margin-right: 5px;
      box-shadow: 0 0 2px 1px rgba(120, 120, 120, 0.49);
      border-radius: 2px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-errorMsg {
    text-align: center;
    color: $color-error;
    font-weight: 700;
  }

  &-securePayment {
    width: 100%;
    height: 80px;
    text-align: center;
    margin-top: 30%;

    > img {
      width: 40%;
    }
  }

  &-fadeIn {
    opacity: 1;
    visibility: visible;
  }

  &-fadeOut {
    opacity: 0;
    visibility: hidden;
  }

  @media only screen and (min-width: 990px) {
    width: $footer-button-width-medium;
    height: 672px;
    // margin: auto;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    bottom: 0;
    z-index: 12;
    border-radius: 4px;
    transition: opacity 0.5s cubic-bezier(0.17, 0.84, 0.44, 1);
    cursor: auto;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      text-align: center;
      background: #fff;
      padding: 0 15px;
      border-radius: 16px 16px 0 0;

      &-btnDone {
        color: $color-pink-dark;
        border: none;
        outline: none;
        font-size: 14pt;
        font-weight: bold;
        padding: 0;
        background: #fff;
        cursor: pointer;
      }

      &-btnCancel {
        width: 16px;
        cursor: pointer;
      }

      &-title {
        font-size: 20pt;
        line-height: 2;
        font-size: 15pt;
        font-weight: 700;
        letter-spacing: 0.7px;
        margin-left: 20px;
      }
    }

    // &-content {
    // 	padding: 0 15px;
    // }

    &-errorMsg {
      font-weight: 700;
      text-align: center;
      color: $color-error;
    }

    &-securePayment {
      width: 100%;
      height: 72px;
      margin-top: 35px;
      text-align: center;

      > img {
        width: 50%;
      }

      &.no-margin {
        margin-top: 0;
      }
    }
  }

  @media only screen and (min-width: 990px) and (min-height: 600px) {
    bottom: 0;
    z-index: 55;
  }

  @media only screen and (min-width: 990px) and (min-height: 701px) {
    bottom: 0;
  }

  @media only screen and (min-width: 1440px) and (min-height: 701px) {
    right: $modal-right;
    height: 672px;
    overflow: visible;
  }

  @include mq(medium-desktop-up) {
    width: $footer-button-width;
  }
}
